import {graphql, useStaticQuery} from 'gatsby'

export const useSiteMetadata = () => {
    const { site } = useStaticQuery(
        graphql`
            query METADATA {
                site {
                    siteMetadata {
                        title
                        description
                    }
                }
            }
        `
    )
    return site.siteMetadata
}