import React from "react";
import Helmet from "react-helmet";
import Img from "gatsby-image";
import { graphql } from "gatsby";

import { useSiteMetadata } from "../hooks/useSiteMetadata";

import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/index.css";

import Header from "../components/header";

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import EmailIcon from '@material-ui/icons/Email';

export default function Home( { data }) {
    const { title, description } = useSiteMetadata();
    return (
        <div>
            <Helmet title={title} description={description}/>
            <Header />
            <main className="hero">
                <div className="hero__left">
                    <div className="avatar__container">
                        <Img fixed={data.file.childImageSharp.fixed } className="avatar" />
                    </div>
                    <h1 className="hero__left--bottom h1Size">
                        Connect with me:
                    </h1>
                    <a href="https://www.linkedin.com/in/avarugh1/" target="_blank" rel="noreferrer"> 
                        <div className="link__item">
                            <LinkedInIcon fontSize="large" /> 
                            <div className="url">
                                linkedin.com/in/avarugh1
                            </div>
                    </div>
                    </a>
                    <a href="https://github.com/avarugh1" target="_blank" rel="noreferrer">
                        <div className="link__item">
                            <GitHubIcon fontSize="large" /> 
                            <div className="url">
                                github.com/avarugh1
                            </div>
                        </div>
                    </a>
                    <div className="link__item">
                        <EmailIcon fontSize="large" /> 
                        <div className="url">
                            avarugh1@gmail.com
                        </div>
                    </div>
                </div>
                <div className="hero__right">
                    <h1 className="header h1Size"> Hello World! </h1>
                    <div className="hero__right--bottom">
                        <p>
                            My name is Anson Varughese. I'm currently a <b>Junior Software Test Engineer</b> at a popular marketing-tech company located in Jericho, New York.
                        </p>
                        <p> 
                            I've worked on teams performing backend, frontend, database, and performance testing in <b>Agile</b> environments. My expertise lies in <b>Java</b> programming but I enjoy working with any object-oriented language. 
                            Here is an abridged list of technologies I've worked with:
                        </p>
                        <ul>
                            <li>
                                <b>Java Automation</b> with TestNG, JBehave, Serenity, Selenium, REST Assured, BrowserStack Automate, and more.
                            </li>
                            <li>
                                <b>Performance Testing</b> with Apache JMeter, New Relic, and Grafana.
                            </li>
                            <li>
                                Clean <b>test case creation</b> in Gherkin syntax managed through HP-ALM and VersionOne.
                            </li>
                        </ul>
                        <p>
                            Prior to starting as a Junior Software Test Engineer, I obtained my <b>Bachelor's degree in Computer Science</b> from <a href="https://www.binghamton.edu/watson/" target="_blank" rel="noreferrer">Binghamton University (SUNY)</a>, Binghamton, New York in the Thomas J. Watson College of Engineering and Applied Science.
                        </p>
                        {/* <p> 
                            This is my personal site where I will write about pretty much anything and everything. I hope you find it interesting. 
                            More of my professional experiences can be found on my <a href="https://www.linkedin.com/in/avarugh1/" target="_blank" rel="noreferrer">LinkedIn</a>.</p>
                        <p>*/}
                        <p>
                             I look forward to new challenges and opportunities for me to learn and grow. At previous organizations, I constantly sought new responsibilities and ensured testing throughout the entire <b>Software Development Life Cycle</b>. 
                        </p>
                        <p>I excel in scenarios which require me to perform my duties independently and efficiently. I have applied my programming knowledge to all facets of the <b>Software Testing Life Cycle</b> to prepare comprehensive test plans, find crucial edge scenarios, and reduce risks for new code features and releases.
                        </p>
                        {/*</p>

                            Please note that all thoughts and postings contained on this site do not represent any of my past, current, or future employers.
                        </p>*/}
                    </div>
                </div>
            </main>
        </div>
    )
}


export const query = graphql`
  query {
    file(relativePath: { eq: "avatar.png" }, sourceInstanceName: { eq: "images" }) {
      childImageSharp {
        fixed(width:250, height:250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
  `